import React, { Component } from "react";
import SyntaxChecker from "../components/ScriptEditor/SyntaxChecker";

import ScriptEditorModal from "../components/ScriptEditor/ScriptEditorModal";

import { deepCopy, filterSample } from "../util/helper";
import { PANEL_QUESTION_IDS } from "../util/constants";


/**
 * Instead of basing this off permissions directly, use the show panel questions prop
 * @param {boolean} showPanelQuestions
 * @returns {function} A function that filters script panel questions
 */
export const getFilterPanelQuestionsFunc = (showPanelQuestions) => (item) =>
  showPanelQuestions || !PANEL_QUESTION_IDS.includes(item.id);

class Script extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      localPrompt: deepCopy(this.props.script),
      displayIndex: 0,
    };
    this.shiftDisplayIndex = this.shiftDisplayIndex.bind(this);
  }
  // If we change campaigns while on the mainscreen then we need to update the local props.
  componentWillReceiveProps(newProps) {
    this.setState({
      localPrompt: deepCopy(newProps.script),
    });
  }
  shiftDisplayIndex() {
    var newDisplayIndex = this.state.displayIndex + 1;
    const phones = Object.keys(this.props.selectedCampaignData.list);
    if (newDisplayIndex == phones.length) {
      newDisplayIndex = 0;
    }
    this.setState({ displayIndex: newDisplayIndex });
  }
  render() {
    // Pass in these props and it should work.
    const {
      list,
      active,
      script,
      updateScript,
      subject,
      getRandomListEntries,
      selectedCampaignData,
      permission,
      selectedCampaignIndex,
      showPanelQuestions,
    } = this.props;

    const editMode = this.state.editMode;
    const phones = Object.keys(selectedCampaignData.list);
    const questionFilterFunction = getFilterPanelQuestionsFunc(showPanelQuestions);

    return (
      <div
        className={"dcm-mainscreen-script" + (editMode ? "-editmode" : "")}
        style={{ height: "100%", overflow: "auto" }}
      >
        {active != "deactivated" && (
          <div className="script-buttons">
            {!editMode && (
              <ScriptEditorModal
                displayIndex={this.state.displayIndex}
                shiftDisplayIndex={this.shiftDisplayIndex}
                script={script}
                initialConditionals={selectedCampaignData.initialconditionals}
                defaultFirstQuestionId={selectedCampaignData.defaultfirstquestionid}
                list={selectedCampaignData.list}
                updateScript={updateScript}
                selectedCampaignIndex={selectedCampaignIndex}
                subject={subject}
                getRandomListEntries={getRandomListEntries}
                permission={permission}
                questionFilterFunction={questionFilterFunction}
                showPanelQuestions={showPanelQuestions}
              />
            )}
            <button className="button" onClick={() => getRandomListEntries(5)}>
              Randomize Sample
            </button>
            <hr />
          </div>
        )}
        {this.state.localPrompt
          .filter(questionFilterFunction)
          .map((q, qIndex) => {
            var sample = {
              unavailable: true,
              question: "",
              answers: [],
            };
            if (phones.length > 0 && this.state.displayIndex !== undefined) {
              sample.unavailable = false;
              // Here is one of two places where we'll need to have a randomization.
              // Right now we only have the first 5 phone numbers' data loaded to save memory
              // so perhaps we should load more? We don't want to load them all so it's not
              // clear how we'll get a "good" random choice, whatever "good" means.
              const replacements = list[phones[this.state.displayIndex]].misc;
              sample.question = filterSample(q.question, replacements);
              if (q.type === "closed") {
                sample.answers = q.answers
                  .map((a) => filterSample(a.text, replacements))
                  .filter((a) => a.length);
              }
            }
            return (
              <div key={qIndex}>
                <div className="dcm-mainscreen-script-item-id">{q.id}</div>
                <div className="dcm-mainscreen-script-item">{q.question}</div>
                <div className="dcm-mainscreen-script-type">
                  <strong>Type: </strong>
                  {q.type}
                </div>
                <div className="dcm-mainscreen-script-answers">
                  {q.type === "closed" ? (
                    <span>
                      <strong>Answers: </strong>
                      {q.answers.map((a) => a.text).join(", ")}
                    </span>
                  ) : null}
                </div>
                <div className="dcm-mainscreen-script-item-sample">
                  <strong>Sample: </strong>
                  {sample.unavailable ? (
                    <span className="sample-unavailable">
                      [no phone list available to sample]
                    </span>
                  ) : (
                    <span>
                      <SyntaxChecker sample={sample.question} />
                      <span className="sample-answers">
                        <SyntaxChecker
                          sample={
                            q.type === "closed"
                              ? ` [${sample.answers.join(", ")}]`
                              : null
                          }
                        />
                      </span>
                    </span>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    );
  }
}

export default Script;
