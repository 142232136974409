import React from "react";
import TextToolsModal from "./TextToolsModal";
import ToggleNewConversations from "./ToggleNewConversations";

export const TextTools = ({ active, campaignid, recipientbatchsize, actionablethreshold, assignOpenRecipients }) => {

  const isActiveCampaign = active !== "deactivated";

  return (
    <>
    <div className="tile is-parent is-vertical pb-0">
      <h4 className="title is-4 has-text-centered">TEXT TOOLS</h4>
      <div className="tile is-parent">
        <div className="tile is-child">
          <div className="field">
            Recipient Batch Size
            <label className="label"> {recipientbatchsize}</label>
          </div>
          <div className="field">
           Threshold for loading more conversations
            <label className="label"> {actionablethreshold}</label>
          </div>
        </div>
        
        {isActiveCampaign && (
          <div className="tile is-child is-right">
            <TextToolsModal campaignid={campaignid} recipientbatchsize={recipientbatchsize} actionablethreshold={actionablethreshold}/>
          </div>
        )}
      </div>
    </div>
    <div className="level px-3">
      <div className="level-left px-3">
        <div className="level-item">
          <div className="field">
            Allow Opening New Conversations
            <label className="label">{assignOpenRecipients ? "Yes" : "No"}</label>
          </div>
        </div>
      </div>
      
      {isActiveCampaign && (
        <div className="level-right px-3">
          <div className="level-item">
            <ToggleNewConversations campaignid={campaignid} assignOpenRecipients={assignOpenRecipients} />
          </div>
        </div>
      )}
    </div>
    </>
  );
};
