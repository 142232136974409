import React, { Component } from "react";
import SelectSearch from "react-select-search";
import BandwidthRegistrationQuestionForm from "./BandwidthRegistrationQuestionForm";
import { S160Field } from "../common/S160Field/S160Field";
import { filterSample } from "../../util/helper";
import { LoadingSpinner } from "../LoadingSpinner";

class BandwidthTools extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prevCampaignId: null,
      assignedLoaded: false,
      assigned: {},
      availableLoaded: false,
      available: [],
      assignedRegDataLoaded: false,
      assignedRegData: {},
      refresh: false,
    };
  }

  componentDidMount() {
    // NOTE: I've renamed the request states to avoid conflicts
    // getRegState: getAllRegistrations
    // assignCampaignState: assignBandwidthRegistrationToCampaign
    this.getAssigned();
    this.getAvailable();
  }

  getAssigned = async () => {
    let bwRegistrationData = null;
    let bwRegistrationDataLoaded = false;

    const prevCampaignId = this.props.campaignid;
    this.setState({ prevCampaignId: prevCampaignId, refresh: false });
    const { maxCampaignsPerRegistration, settings } = await this.props.getAssignedRegistration();

    // If there are assigned settings, fetch the registration data from Bandwidth
    if (settings.length !== 0) {
      const settingsId = settings.find((s) => s.campaign_list.includes(prevCampaignId)).settings_id;
      bwRegistrationData = await this.props.getAssignedRegDataFromBandwidth(settingsId);
      bwRegistrationDataLoaded = bwRegistrationData !== undefined;
    }

    this.setState({
      prevCampaignId: prevCampaignId,
      assignedLoaded: true,
      assigned: settings.find((setting) => setting.campaign_list.includes(prevCampaignId)) || {},
      maxCampaignsPerRegistration,
      assignedRegDataLoaded: true,
      assignedRegData: bwRegistrationData,
      refresh: false,
    });
  };

  getAvailable = async () => {
    // NOTE: I think the API should handle the maxCampaignsPerRegistration so that the users don't see unavailable registrations
    const { maxCampaignsPerRegistration, settings } = await this.props.getAvailableRegistrations();
    this.setState({
      availableLoaded: true,
      available: settings.sort((a, b) =>
        a.campaign_list.length > b.campaign_list.length ? 1 : -1
      ),
      refresh: false,
    });
  };

  onRegistrationQuestionSubmit = (questionVals) => {
    // combine the current question values with the other registration data
    const registrationData = {
      ...this.props.assignedRegDataFromBandwidth,
      ...questionVals,
    };
    this.props.postAssignedRegDataFromBandwidth(this.state.assigned.settings_id, registrationData, true);
    this.setState({ refresh: true });
  };

  componentDidUpdate() {
    const { getRegState } = this.props;
    const { loading } = getRegState;

    // Can't do anything while loading
    if (loading) {
      return;
    }

    // On campaign change, reload selected data
    if (this.props.campaignid !== this.state.prevCampaignId || this.state.refresh) {
      this.getAssigned();
      return;
    }
  }

  unassignRegistration = async () => {
    console.log("Unassigning", this.state.assigned.settings_id, this.props.campaignid);
    await this.props.unassignBandwidthRegistration(
      this.state.assigned.settings_id,
      this.props.campaignid
    );
    this.setState({
      assigned: {},
      assignedRegDataLoaded: false,
      assignedRegData: {},
    });
    await this.getAvailable();
  };

  onDropdownChange = async (selectedBwSettingsId) => {
    const campaignId = this.props.campaignid;
    const { settings } = await this.props.assignBandwidthRegistrationToCampaign(
      selectedBwSettingsId,
      campaignId,
      [false, campaignId]
    );

    // If there are assigned settings, fetch the registration data from Bandwidth
    let bwRegistrationData = null;
    if (settings.length !== 0) {
      const settingsId = settings.find((s) => s.campaign_list.includes(campaignId)).settings_id;
      bwRegistrationData = await this.props.getAssignedRegDataFromBandwidth(settingsId);
    }

    this.setState({
      assigned: settings.find((setting) => setting.campaign_list.includes(campaignId)) || {},
      assignedRegDataLoaded: true,
      assignedRegData: bwRegistrationData,
    });
  };

  render() {
    const {
      has_unassigned_recipients,
      getRegState,
      assignedRegDataFromBandwidth, // TODO: rename to assignedRegQDataFromBandwidth
      getAssignedRegDataFromBandwidth,
    } = this.props;

    if (getRegState.loading) {
      return <LoadingSpinner />;
    }

    const { assigned: assignedBwSetting, available } = this.state;

    let dropdownValue;

    if (assignedBwSetting) {
      dropdownValue = assignedBwSetting.settings_id;
    } else {
      dropdownValue = null;
    }

    /* ASSEMBLE DROPDOWN OPTIONS in these next 3 blocks */

    // Selected Options: the options currently saved for each sample question IN BANDWIDTH (not S160)
    let selectedSampleOptions = {};
    console.log("state.assignedRegData", this.state.assignedRegData);
    selectedSampleOptions =
      this.state.assignedRegDataLoaded && this.state.assignedRegData
        ? Object.keys(this.state.assignedRegData).reduce((acc, key) => {
            if (key.startsWith("sample")) {
              acc[key] = this.state.assignedRegData[key];
            }
            return acc;
          }, {})
        : {};

    // Campaign Options: one for each sample question from the current campaign script
    let recipientVars = {};
    // Check for recipients before extracting misc
    if (this.props.sampleRecipients.length && this.props.sampleRecipients[0].misc) {
      recipientVars = this.props.sampleRecipients[0].misc;
    }
    const regQCampaignOptions = this.props.script.map((item, i) => {
      return {
        questionTitle: `Question ${i + 1}`,
        value: filterSample(item.question, recipientVars),
        questionLabel: `${item.id} (${item.type})`,
      };
    });

    // Combined Options: merges the Selected & Campaign options together for the form dropdowns
    // Note that each dropdown will have 0 or 1 unique options reflecting the value previously saved in BW
    const sampleOptions = {};
    Object.entries(selectedSampleOptions).map(([key, val]) => {
      const fieldOpts = [...regQCampaignOptions];
      // add a 'previously saved' option for questions that have an answer saved in BW
      // (Note that these could be from another campaign and we won't know the campaign or question)
      if (regQCampaignOptions && selectedSampleOptions && selectedSampleOptions[key]) {
        fieldOpts.unshift({
          questionLabel: "PREVIOUSLY SAVED QUESTION",
          value: val,
          questionTitle: "",
        });
      }
      // add an empty option to every dropdown
      fieldOpts.unshift({
        questionLabel: "",
        value: "",
        questionTitle: "",
      });
      sampleOptions[key] = fieldOpts;
    });

    // console.group("Sample Q Options");
    // console.log("sampleOptions", sampleOptions);
    // console.log("selectedSampleOptions", selectedSampleOptions);
    // console.groupEnd();

    let dropdownList = assignedBwSetting.settings_id
      ? [assignedBwSetting]
      : // Already sorted by by number of campaign assignments. Less at the top
        available.filter(
          (setting) => setting.campaign_list.length < this.state.maxCampaignsPerRegistration
        );

    return (
      <div>
        <h4 className="title is-4 has-text-centered">BANDWIDTH SETTINGS</h4>
        <div className="columns is-variable is-4">
          <div className="column">
            <h1 className="title is-4">Assigned Registration</h1>
            <div>
              <SelectSearch
                options={dropdownList.map((setting) => ({
                  value: setting.settings_id,
                  name: `${setting.settings_id} - ${setting.reg_id} (${setting.campaign_list.length})`,
                }))}
                value={dropdownValue}
                // We want to disable the dropdown if there is a selected registration
                disabled={!!dropdownValue}
                search
                emptyMessage="No registrations found"
                placeholder="Choose a registration"
                onChange={this.onDropdownChange}
              />
              {!!dropdownValue && (
                <button className="button is-danger mt-2" onClick={this.unassignRegistration}>
                  Unassign Registration
                </button>
              )}
            </div>

            {!!dropdownValue && (
              <div className="mt-4">
                <S160Field
                  name="Subaccount ID"
                  value={assignedBwSetting.subaccount_id}
                  fallbackValue="-- Subaccount ID not found --"
                />
                <S160Field
                  name="Location ID"
                  value={assignedBwSetting.location_id}
                  fallbackValue="-- Location ID not found --"
                />
                <S160Field
                  name="Registration ID"
                  value={assignedBwSetting.reg_id}
                  fallbackValue="-- Registration ID not found --"
                />
                <S160Field
                  name="Are there unassigned recipients?"
                  value={has_unassigned_recipients ? "Yes" : "No"}
                  fallbackValue="-- Unassigned recipients not found --"
                />
              </div>
            )}
          </div>

          <div className="column is-8">
            {Object.entries(this.state.assigned).length !== 0 && (
              <>
                <h1 className="title is-4">Sample Messages</h1>

                {this.props.script.length == 0 && (
                  <div className="box mt-2">
                    <strong>Missing Script</strong>
                    <br />
                    Add a script to select sample messages from this campaign.
                  </div>
                )}

                {this.props.script.length > 0 && (
                  <BandwidthRegistrationQuestionForm
                    sampleOptions={sampleOptions}
                    selectedSampleOptions={selectedSampleOptions}
                    onRegistrationQuestionSubmit={this.onRegistrationQuestionSubmit}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default BandwidthTools;
